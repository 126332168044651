import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';

import styles from './PriceTiersSection.module.scss';
import { Section } from '../../../components/Page';
import PriceTiersCallout from './PriceTiersCallout';
import Grid from '../../../components/layout/Grid';
import PriceTier from './PriceTier';

type Props = {
  bg: 'blue' | 'cyan' | 'green';
  calloutProps?: React.ComponentProps<typeof PriceTiersCallout>;
  priceTiers: Array<React.ComponentProps<typeof PriceTier>>;
  bottomContent?: ReactNode;
  topContent?: ReactNode;
};

const PriceTiersSection: React.FunctionComponent<Props> = (props) => {
  if (props.priceTiers.length === 0) {
    return null;
  }

  const [expandedFeaturesTier, setExpandedFeaturesTier] = useState<string | undefined>(props.priceTiers[0].title);

  const toggleFeatures = (tierTitle: string) => {
    setExpandedFeaturesTier(expandedFeaturesTier === tierTitle ? undefined : tierTitle);
  };

  return (
    <Section
      className={classNames({
        [styles.blueBg]: props.bg === 'blue',
        [styles.cyanBg]: props.bg === 'cyan',
        [styles.greenBg]: props.bg === 'green',
      })}
      padding="sm"
      containerProps={{ paddingClassName: styles.priceTiersContainerPadding }}
    >
      {props.topContent || null}

      <div className={styles.priceTierList}>
        {props.calloutProps && <PriceTiersCallout {...props.calloutProps} />}

        <Grid container spacing={1} className={styles.priceTierGrid}>
          {props.priceTiers.map((priceTier) => (
            <Grid key={priceTier.title} item lg={3} md="auto" xs={12}>
              <PriceTier
                {...priceTier}
                featuresExpanded={expandedFeaturesTier === priceTier.title}
                onToggleFeatures={() => toggleFeatures(priceTier.title)}
              />
            </Grid>
          ))}
        </Grid>

        {props.bottomContent || null}
      </div>
    </Section>
  );
};

export default PriceTiersSection;
