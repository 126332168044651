import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { Theme } from '@material-ui/core';

import styles from './PriceTier.module.scss';
import CallToAction from '../../../components/CallToAction';
import Flex from '../../../components/layout/Flex';
import Icon from '../../../components/icons/Icon';
import Link from '../../../components/Link';
import Text from '../../../components/Text';

type Props = {
  title: string;
  borderTopColor: 'none' | 'orange' | 'purple' | 'blue' | 'yellow' | 'green' | 'red' | 'lilac' | 'cyan' | 'pale-green';
  price: React.ReactNode;
  priceIsWords?: boolean;
  priceDetails?: React.ReactNode;
  selectionDisabled?: boolean;
  currentTier?: boolean;
  ctaLink?: ReactElement<PriceTierCtaLinkProps>;
  mainFeaturesHeight: 'short' | 'tall';
  mainFeatures: React.ReactNode[];
  secondaryFeatures: string[];
  featuresExpanded?: boolean;
  onToggleFeatures?(): void;
};

const PriceTier: React.FunctionComponent<Props> & { CtaLink: typeof PriceTierCtaLink } = (props) => {
  const ctaCss = (theme: Theme) => ({
    [theme.breakpoints.only('sm')]: {
      width: '100%',
    },
  });

  return (
    <div className={classNames(styles.container, styles[`borderTop-${props.borderTopColor}`])}>
      <Text el="h2" variant="heading2xs" css={{ marginBottom: '2rem', fontWeight: 300, fontSize: '2.4rem' }}>
        {props.title}
      </Text>
      <Flex className={styles.priceWrapper} alignItems="center">
        <div
          className={classNames(styles.price, {
            [styles.priceWords]: props.priceIsWords,
          })}
        >
          {props.price}
        </div>
        <div className={styles.priceDetails}>{props.priceDetails}</div>
      </Flex>

      <div className={styles.btnWrapper}>{props.ctaLink}</div>

      <div
        className={classNames({
          [styles.mainFeaturesShort]: props.mainFeaturesHeight === 'short',
          [styles.mainFeaturesTall]: props.mainFeaturesHeight === 'tall',
        })}
      >
        {props.mainFeatures.map((feature, i) => (
          <div key={i}>{feature}</div>
        ))}
      </div>

      <div
        className={classNames(styles.secondaryFeaturesContainer, {
          [styles.collapsed]: !props.featuresExpanded,
        })}
      >
        <hr className={styles.divider} />

        {props.secondaryFeatures.map((feature, i) => (
          <div key={i} className={styles.secondaryFeature}>
            {feature}
          </div>
        ))}
      </div>

      <div className={classNames(styles.viewFeaturesBtnContainer, { [styles.expanded]: props.featuresExpanded })}>
        <button className={styles.viewFeaturesBtn} onClick={props.onToggleFeatures}>
          <span className={styles.viewFeaturesBtnTxt}>
            {props.featuresExpanded ? 'View less features' : 'View all features'}
          </span>
          <Icon
            name="arrowDown"
            height="2.4rem"
            className={classNames({
              [styles.arrowExpanded]: props.featuresExpanded,
              [styles.arrowCollapsed]: !props.featuresExpanded,
            })}
          />
        </button>
      </div>
    </div>
  );
};

type PriceTierCtaLinkProps = React.ComponentProps<typeof Link> & {
  genericCta?: boolean;
  edu?: boolean;
};

export const PriceTierCtaLink: React.FunctionComponent<PriceTierCtaLinkProps> = (props) => {
  const ctaCss = (theme: Theme) => ({
    [theme.breakpoints.only('sm')]: {
      width: '100%',
    },
  });
  const { genericCta, edu, ...otherProps } = props;

  if (genericCta) {
    return (
      <CallToAction
        text={typeof props.children === 'string' ? props.children : undefined}
        variant={props.variant === 'btnSm' ? 'btnSm' : 'btnSmOutline'}
        onClick={props.onClick}
        edu={edu}
        css={ctaCss}
        id={otherProps.id}
      />
    );
  }

  return (
    <Link
      variant="btnSmOutline"
      css={(theme: Theme) => ({
        [theme.breakpoints.only('sm')]: {
          width: '100%',
        },
      })}
      {...otherProps}
    >
      {props.children}
    </Link>
  );
};

PriceTier.CtaLink = PriceTierCtaLink;

export default PriceTier;
