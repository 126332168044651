import { useTheme } from '@material-ui/core';
import React, { ReactNode } from 'react';
import Text from '../../../components/Text';
import Box from '../../../components/layout/Box';
import Link from '../../../components/Link';
import SubscriptionsLilacIcon from '../media/subscriptionsIcon.svg';
import SubscriptionsYellowIcon from '../media/subscriptionsYellowIcon.svg';
import K12Icon from '../media/k12.svg';

type Props = {
  description: ReactNode;
  onLearnMoreClick?(e: React.MouseEvent): void;
  linkHref: string;
  linkText?: string;
  title?: string;
  iconType?: 'subsLilac' | 'subsYellow' | 'edu';
};

const PriceTiersCallout: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const iconByType = {
    subsLilac: <SubscriptionsLilacIcon width="100%" />,
    subsYellow: <SubscriptionsYellowIcon width="100%" />,
    edu: <K12Icon width="100%" />,
  };

  return (
    <div
      css={{
        backgroundColor: '#fff',
        padding: '24px 24px',
        marginBottom: '4rem',
        borderRadius: '8px',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
      }}
    >
      {props.iconType && (
        <Box flexBasis="auto" width={{ xs: '6.4rem', sm: '11.8rem' }} height={{ xs: '6.4rem', sm: '11.8rem' }}>
          {iconByType[props.iconType]}
        </Box>
      )}
      <Box flex={1} flexBasis={{ xs: '100%', sm: 0 }} p={{ xs: '8px 0 0 0', sm: '0 0 0 27px', md: '0 40px' }}>
        <Text el="h2" variant="headingSm" fontWeight="thin" css={{ marginBottom: '0.8rem' }}>
          {props.title || 'Subscriptions'}
        </Text>
        <Text el="p" variant="paragraphMd" fontWeight="thin" css={{ maxWidth: '75rem' }}>
          {props.description}
        </Text>
      </Box>
      <Link
        variant="btnSmOutline"
        href={props.linkHref}
        onClick={props.onLearnMoreClick}
        css={{
          flexBasis: 'auto',
          marginTop: '4rem',
          [theme.breakpoints.only('sm')]: {
            width: '100%',
            flexBasis: '100%',
          },
          [theme.breakpoints.up('md')]: {
            marginTop: 0,
          },
        }}
      >
        {props.linkText || 'Learn more'}
      </Link>
    </div>
  );
};

PriceTiersCallout.defaultProps = {
  iconType: 'subsLilac',
};

export default PriceTiersCallout;
